import { useCalculateFiatFromCrypto } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpAmount, TpCryptoCurrency, TpSimpleAmount } from '@noah-labs/shared-currencies';

type PpUseSendAmounts = {
  cryptoAmount: TpAmount;
  cryptoCurrency: TpCryptoCurrency | undefined;
  fiatAmount?: TpAmount;
};

type TpUseSendAmountsResponse = {
  fetchedAt: string | undefined;
  fiatAmount: TpSimpleAmount;
  price: TpSimpleAmount;
};

export function useSendAmounts({
  cryptoAmount,
  cryptoCurrency,
  fiatAmount,
}: PpUseSendAmounts): TpUseSendAmountsResponse {
  /**
   * Fetch the fiatAmount for the cryptoAmount in the paymentRequestData,
   * so that we can display it on the confirm & complete screeens.
   * Always fetch a new fiatAmount in case the stored value is out of date
   */
  const { data: userData } = useUserInitUi();
  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount,
    cryptoCurrency,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    options: {
      // don't fetch if we already have a 'saved' fiatAmount
      enabled: Boolean(!fiatAmount && cryptoAmount),
    },
    priceProvider: 'market',
  });

  const newfiatAmount = fiatAmount?.toString() || priceData?.fiatAmount;

  return {
    fetchedAt: priceData?.fetchedAt,
    fiatAmount: newfiatAmount,
    price: priceData?.price,
  };
}
