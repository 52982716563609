import { useEffect } from 'react';
import { useCardIssuerNotificationsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { usePushAlert } from '@noah-labs/fe-shared-ui-components';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { getCardIssuerNotificationAlert } from '../controllers/buy/utils';

export function useCardIssuerNotifications(cardIssuerName: string | null | undefined): void {
  const pushAlert = usePushAlert();
  const { data } = useCardIssuerNotificationsQuery(
    { Input: { IssuerName: cardIssuerName ?? '' } },
    { enabled: Boolean(cardIssuerName) },
  );

  useEffect(() => {
    const items = data?.cardIssuerNotifications.items;
    if (isFalseyOrEmptyArray(items)) {
      return;
    }

    items.forEach((notification) => {
      const alertProps = getCardIssuerNotificationAlert(notification);

      if (!alertProps) {
        return;
      }

      pushAlert(alertProps);
    });
  }, [data?.cardIssuerNotifications, pushAlert]);
}
