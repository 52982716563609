import { CurrencyCode as SchemaCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { AccountsQuery } from './data/wallet.generated';
import { getDefaultAccounts } from './defaultAccounts';
import type { TpAccount, TpFindAccount } from './types';
import { selectAccount } from './utils';

type TpFeatureData = { currencies: SchemaCurrencyCode[]; isFeatureEnabled: boolean };

const accountSortOrder = [
  SchemaCurrencyCode.BTC,
  SchemaCurrencyCode.BTC_TEST,
  SchemaCurrencyCode.ETH,
  SchemaCurrencyCode.ETH_TEST_SEPOLIA,
  SchemaCurrencyCode.USDC,
  SchemaCurrencyCode.USDC_TEST,
  SchemaCurrencyCode.USDT,
  SchemaCurrencyCode.USDT_TEST,
];

export function filterPlaceholderAccounts(
  accounts: AccountsQuery['accounts']['items'],
  placeholderAccounts: TpAccount[],
): TpAccount[] {
  const existingAccounts = new Set(accounts.map((c) => c.CurrencyCode));

  const filteredPlaceholderAccounts = placeholderAccounts.filter(
    (c) => !existingAccounts.has(c.CurrencyCode),
  );
  return [...accounts, ...filteredPlaceholderAccounts];
}

/**
 * sortFilterWithFallback can be used to display accounts in the UI,
 * controls users accounts, placeholder accounts and also filters
 * accounts based on currencies settings and feature data
 */
export function sortFilterWithFallback(
  userAccounts: AccountsQuery['accounts']['items'],
  { currencies, isFeatureEnabled }: TpFeatureData,
): TpAccount[] | undefined {
  const defaultAccounts = getDefaultAccounts();
  const filteredDefaultAccounts = defaultAccounts.filter((acc) =>
    currencies.includes(acc.CurrencyCode),
  );

  const filteredUserAccounts = userAccounts.filter((acc) => currencies.includes(acc.CurrencyCode));

  const placeholderAccounts = isFeatureEnabled ? filteredDefaultAccounts : [defaultAccounts[0]];
  const accounts = filterPlaceholderAccounts(filteredUserAccounts, placeholderAccounts);

  const sortedAccounts = accounts
    .filter((acc) => acc.CurrencyCode !== SchemaCurrencyCode.NOAH)
    .sort(
      (a, b) => accountSortOrder.indexOf(a.CurrencyCode) - accountSortOrder.indexOf(b.CurrencyCode),
    );

  return sortedAccounts;
}

/**
 * filterWithFallback returns users accounts and placeholder accounts without
 * settings filter or restricted currencies filter, it should be used
 * for select cases and not to display accounts
 */
export function filterWithFallback(data: AccountsQuery): AccountsQuery {
  const accounts = filterPlaceholderAccounts(data.accounts.items, getDefaultAccounts());

  return {
    ...data,
    accounts: {
      ...data.accounts,
      items: accounts,
    },
  };
}

export function selectFromFilterWithFallback({
  AccountType,
  CurrencyCode,
  data,
}: TpFindAccount): TpAccount | undefined {
  return selectAccount({ AccountType, CurrencyCode, data: filterWithFallback(data) });
}
