import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { css } from '@emotion/react';
import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { TpSelectOption } from '@noah-labs/fe-shared-ui-components';
import {
  CheckoutField,
  FormGrid,
  FormItem,
  InputField,
  MuiSvgIcon,
  Switch,
  useFramesFields,
} from '@noah-labs/fe-shared-ui-components';
import { usePrevious } from '@noah-labs/fe-shared-ui-shared';
import type { TpCkoCardDetailsSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { logger } from '@noah-labs/shared-logger/browser';
import type {
  FrameCardTokenizationFailedEvent,
  FrameCardTokenizedEvent,
  FramesInitProps,
} from 'frames-react';
import { CardNumber, Cvv, ExpiryDate, Frames } from 'frames-react';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import useScript from 'react-script-hook';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import { BillingAddressesForm } from './BillingAddresses/BillingAddressesForm';
import type { TpBillingAddressForm } from './BillingAddresses/schema';

const framesUrl = 'https://cdn.checkout.com/js/framesv2.min.js';

export type TpTokenizedCardForm = TpCkoCardDetailsSchema & {
  billingAddress: TpBillingAddressForm;
  saveCard: boolean;
};

export type PpTokenizedCardForm = {
  countries: TpSelectOption[] | undefined;
  defaultAddress: TpBillingAddressForm | undefined | null;
  formId: string;
  isVisible: boolean;
  methods: UseFormReturn<TpTokenizedCardForm>;
  onCardTokenized?: (cardTokenized: FrameCardTokenizedEvent, save: boolean) => Promise<void>;
  saveToggle: boolean;
};

// frames doesn't support custom fonts as yet
const fieldFontFamily = 'Helvetica,sans-serif;';

export function TokenizedCardForm({
  countries,
  defaultAddress,
  formId,
  isVisible,
  methods,
  onCardTokenized,
  saveToggle,
}: PpTokenizedCardForm): React.ReactElement | null {
  const [framesLoading, framesError] = useScript({ checkForExisting: true, src: framesUrl });
  const [tokenizedFormKey, resetForm] = useReducer((key: number) => key + 1, 0);
  const wasVisible = usePrevious(isVisible);

  const theme = useTheme();
  const styles = {
    ckoInputBase: {
      color: theme.palette.text.primary,
      fontFamily: fieldFontFamily,
      fontSize: theme.typography.paragraphBodyM?.fontSize,
      fontWeight: theme.typography.paragraphBodyM?.fontWeight,
      letterSpacing: 'normal',
      lineHeight: '1.2',
      padding: theme.spacing(0, 2),
    },
    ckoInputPlaceholder: {
      base: {
        color: theme.palette.text.light,
      },
    },
    input: {
      fontFamily: fieldFontFamily,
    },
    saveCard: css`
      margin-top: ${theme.spacing(3)};
      justify-content: space-between;
    `,
  };

  const { formState, setFocus, setValue, watch } = methods;
  const billingAddress = watch('billingAddress');
  const cardholderName = watch('cardholderName');

  const framesConfig: FramesInitProps = useMemo(() => {
    const config: FramesInitProps = {
      acceptedPaymentMethods: webConfigBrowser.cko.supportedFiatPaymentCards,
      cardholder: {
        billingAddress: {
          addressLine1: billingAddress.Street,
          addressLine2: billingAddress.Street2 ?? undefined,
          city: billingAddress.City,
          country: billingAddress.CountryCode,
          state: billingAddress.State,
          zip: billingAddress.PostCode,
        },
        name: cardholderName,
      },
      publicKey: webConfigBrowser.cko.publicKey,
      style: {
        base: styles.ckoInputBase,
        placeholder: styles.ckoInputPlaceholder,
      },
    };

    return config;
  }, [
    billingAddress.Street,
    billingAddress.Street2,
    billingAddress.City,
    billingAddress.CountryCode,
    billingAddress.State,
    billingAddress.PostCode,
    cardholderName,
    styles.ckoInputBase,
    styles.ckoInputPlaceholder,
  ]);

  const {
    CardSchemeIcon,
    fieldsFocus: ckoFocus,
    handleFrameBlur,
    handleFrameFocus,
    handleFrameValidation,
    handlePaymentMethod,
  } = useFramesFields<TpTokenizedCardForm>({
    setFocus,
    setValue,
  });

  const handleCardTokenizedFailed = useCallback(
    (errors: FrameCardTokenizationFailedEvent): void => {
      logger.debug('card tokenization failed', errors);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      Frames.enableSubmitForm();
      await Frames.submitCard();
    } catch (error) {
      logger.error(error);
    }
  }, []);

  /**
   * Add event handler for card tokenization
   * Frames handlers do not update when the value of the callback changes
   */
  const saveCard = watch('saveCard');
  useEffect(() => {
    if (!onCardTokenized || framesLoading) {
      return;
    }
    Frames.removeAllEventHandlers('cardTokenized');

    Frames.addEventHandler(
      'cardTokenized',
      // @ts-expect-error type definition is wrong
      (cardTokenized: FrameCardTokenizedEvent) => onCardTokenized(cardTokenized, saveCard),
    );
  }, [framesLoading, saveCard, onCardTokenized]);

  /**
   * Resets the form whenever the user navigates away from the page
   */
  useEffect(() => {
    if (isVisible || !wasVisible) {
      return;
    }

    resetForm();
  }, [isVisible, wasVisible]);

  if (framesLoading || framesError) {
    return null;
  }

  return (
    <Frames
      key={tokenizedFormKey}
      cardTokenizationFailed={handleCardTokenizedFailed}
      config={framesConfig}
      frameBlur={handleFrameBlur}
      frameFocus={handleFrameFocus}
      frameValidationChanged={handleFrameValidation}
      paymentMethodChanged={handlePaymentMethod}
    >
      <FormProvider {...methods}>
        <form id={formId} onSubmit={methods.handleSubmit(handleSubmit)}>
          <FormGrid>
            <FormItem>
              <InputField
                fullWidth
                required
                dataQa="name"
                inputProps={{ sx: styles.input }}
                label="Cardholder Name"
                name="cardholderName"
              />
            </FormItem>
            <FormItem>
              <CheckoutField
                // TODO (cs): use 40px for better visuals until we get updated icons
                EndSlot={<MuiSvgIcon svg={CardSchemeIcon} sx={{ width: '40px' }} />}
                error={formState.errors.cardNumber}
                FieldSlot={CardNumber}
                focused={ckoFocus.cardNumber}
                label="Card Number"
              />
            </FormItem>
            <FormItem xs={6}>
              <CheckoutField
                error={formState.errors.expiryDate}
                FieldSlot={ExpiryDate}
                focused={ckoFocus.expiryDate}
                label="Expiry Date"
              />
            </FormItem>
            <FormItem xs={6}>
              <CheckoutField
                error={formState.errors.cvv}
                FieldSlot={Cvv}
                focused={ckoFocus.cvv}
                label="CVV"
              />
            </FormItem>
            <FormItem>
              <Divider />
            </FormItem>
            <FormItem>
              <Typography variant="paragraphBodyMBold">Billing address</Typography>
            </FormItem>
            <FormItem>
              <BillingAddressesForm countries={countries} defaultAddress={defaultAddress} />
            </FormItem>
            <FormItem>
              {saveToggle && (
                <Switch
                  dataQa="save-card"
                  inputCss={styles.saveCard}
                  label={
                    <Typography color="text.light" variant="paragraphBodyM">
                      Save this card for future payments
                    </Typography>
                  }
                  labelPlacement="start"
                  name="saveCard"
                />
              )}
            </FormItem>
          </FormGrid>
        </form>
      </FormProvider>
    </Frames>
  );
}
