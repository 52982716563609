import { useCallback, useEffect } from 'react';
import { disableRefetchRetry } from '@noah-labs/fe-shared-data-access-shared';
import {
  useLnAddressInvoiceProxyQuery,
  useLnPaymentSendMutation,
} from '@noah-labs/fe-shared-data-access-wallet';
import type { TpOnSign } from '@noah-labs/fe-shared-feature-signing';
import { useSignWithdrawal } from '@noah-labs/fe-shared-feature-signing';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpLightningAddressData } from '@noah-labs/fe-shared-ui-address';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { generatePath, LnurlErrorAlert, usePushAlert } from '@noah-labs/fe-shared-ui-components';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { ConfirmScene, useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { useSendAmounts } from '../../hooks/useSendAmounts';
import type { SmLightningSend } from '../types';

type PpLightningSendConfirmController = Pick<
  TpStateMachine<SmLightningSend>,
  'state' | 'updateState'
> & {
  onPaymentSent?: () => void;
};
export function LightningSendConfirmController({
  onPaymentSent,
  state,
  updateState,
}: PpLightningSendConfirmController): React.ReactElement {
  const { push } = useRouter();
  const pushAlert = usePushAlert();
  const { AccountType, cryptoCurrency, cryptoNetwork, params } = useWalletParams();
  const { data: userData } = useUserInitUi();

  const addressType = state.paymentRequestData?.addressType;
  const address = state.paymentRequestData?.address;
  const lnUrlLink = state.paymentRequestData?.lnUrlLink;

  const isLnAddress = addressType === 'lnaddress';
  const isLnUrl = addressType === 'lnurl';

  /**
   * Setup but disable a query to get a payment request from our API - will only be used if this is a LNAddress payment
   */
  const {
    error: lnAddressInvoiceError,
    isLoading: lnAddressInvoiceIsLoading,
    refetch: lnAddressInvoiceQuery,
  } = useLnAddressInvoiceProxyQuery(
    {
      Input: {
        Amount: state.cryptoAmount,
        LightningAddress: isLnAddress ? address : undefined,
        LnurlLink: lnUrlLink,
      },
    },
    { enabled: false, ...disableRefetchRetry },
  );

  /**
   * Setup the mutation to pay the payment request
   */
  const {
    error: lnPaymentSendError,
    isLoading: lnPaymentSendIsLoading,
    mutateAsync: lnPaymentSend,
  } = useLnPaymentSendMutation();

  const isLnAddressOrLnURL = isLnAddress || isLnUrl;

  useEffect(() => {
    /**
     * Skip this step if the payment request was entered directly (ie not from an LNAddress or LNURL)
     */
    if (!isUndefinedOrNull(state.paymentRequest) || !isLnAddressOrLnURL) {
      return;
    }

    async function queryLnInvoice(): Promise<void> {
      /**
       * If this is an LNAddress payment now we need to get the paymentRequest from our proxy
       */
      const { data } = await lnAddressInvoiceQuery({ throwOnError: false });
      switch (data?.lightningAddressInvoiceProxy.__typename) {
        case 'LightningAddressInvoiceProxySuccess': {
          const updatedData = state.paymentRequestData ?? ({} as TpLightningAddressData);
          updatedData.paymentHash = data.lightningAddressInvoiceProxy.PublicID;
          updateState({
            paymentRequest: data.lightningAddressInvoiceProxy.PaymentRequest,
            paymentRequestData: updatedData,
          });
          break;
        }
        case 'LnurlError':
          pushAlert(LnurlErrorAlert(data.lightningAddressInvoiceProxy));
          break;
        default:
      }
    }
    void queryLnInvoice();
  }, [
    isLnAddressOrLnURL,
    lnAddressInvoiceQuery,
    pushAlert,
    state.paymentRequest,
    state.paymentRequestData,
    updateState,
  ]);

  /**
   * Get params and fetch the fiatAmount for the cryptoAmount in the paymentRequestData,
   * so that we can display it on the confirm & complete screeens.
   * Always fetch a new fiatAmount in case the stored value is out of date
   */
  const { fetchedAt, fiatAmount, price } = useSendAmounts({
    cryptoAmount: state.cryptoAmount,
    cryptoCurrency,
    fiatAmount: state.fiatAmount,
  });
  useEffect(() => {
    updateState({ fetchedAt, fiatAmount, price });
  }, [fetchedAt, fiatAmount, price, updateState]);

  const sendPayment = useCallback(
    async ({ signature }: TpOnSign) => {
      if (!userData) {
        return;
      }
      try {
        /**
         * Request our API to pay the payment request
         */
        await lnPaymentSend({
          Input: {
            AccountType,
            Amount: state.cryptoAmount,
            CurrencyCode: cryptoCurrency.code,
            PaymentRequest: state.paymentRequest || '',
            ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            RequestedAmount: {
              Amount: state.fiatAmount,
              FetchedAt: state.fetchedAt,
              FiatCurrency: userData.userProfile.fiatCurrency.code,
              Price: state.price,
            },
          },
        });

        if (onPaymentSent) {
          onPaymentSent();
        }

        push(generatePath(walletRoutes().send.lightning.complete.path, params));
      } catch (e) {
        // useWalletError handles error
      }
    },
    [
      AccountType,
      cryptoCurrency,
      lnPaymentSend,
      onPaymentSent,
      params,
      push,
      state.cryptoAmount,
      state.fetchedAt,
      state.fiatAmount,
      state.paymentRequest,
      state.price,
      userData,
    ],
  );

  const isLoading = lnPaymentSendIsLoading || lnAddressInvoiceIsLoading;

  const { loading: signLoading, sign } = useSignWithdrawal({
    cryptoNetwork,
    payload: {
      AccountType,
      Amount: state.cryptoAmount,
      CurrencyCode: cryptoCurrency.code,
      Destination: state.paymentRequestData?.paymentHash || '',
      inputType: 'withdraw',
    },
  });

  const readyForPayment =
    !signLoading &&
    !isUndefinedOrNull(state.paymentRequest) &&
    !isUndefinedOrNull(state.paymentRequestData?.paymentHash);

  const onConfirm = useCallback(async () => {
    await sign(sendPayment);
  }, [sign, sendPayment]);

  const { ApiErrorScene } = useWalletError(lnPaymentSendError || lnAddressInvoiceError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConfirmScene
      addressData={state.paymentRequestData}
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      cryptoNetwork={cryptoNetwork!}
      cryptoUnit={userData?.userProfile.DisplayUnit}
      fiatAmount={state.fiatAmount}
      fiatCurrency={userData?.userProfile.fiatCurrency}
      isCtaDisabled={!readyForPayment}
      isLoading={isLoading}
      primaryCurrency={userData?.userProfile.PrimaryCurrency}
      onConfirm={onConfirm}
    />
  );
}
